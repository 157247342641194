<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div
        class="searchWrapper"
        @keydown.enter.prevent="
          pageNum = 1;
          searchData;
        "
      >
        <el-form
          :rules="searchRule"
          ref="form"
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Opinion_ID')" prop="adviseId">
                <el-input
                  :maxlength="19"
                  size="medium"
                  v-model.trim="formInline.adviseId"
                  auto-complete="off"
                  placeholder="请输入准确信息"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.phone_number')" prop="mobile">
                <el-input
                  :maxlength="11"
                  size="medium"
                  v-model.trim="formInline.mobile"
                  auto-complete="off"
                  placeholder="请输入准确信息"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.title')" prop="content">
                <!-- <el-input
              :maxlength="11"
              size="medium"
              v-model.trim="formInline.content"
              auto-complete="off"
              placeholder="请输入准确信息"/> -->
                <el-autocomplete
                  v-model="memberAdviseIds"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入准确信息"
                  @select="handleSelect"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.Opinion_status')" prop="operateType">
                <el-select v-model="formInline.operateType">
                  <el-option label="全部" value="" />
                  <el-option label="待回复" :value="1" />
                  <el-option label="已回复" :value="3" />
                  <!-- <el-option label="已关闭" :value="3"/> -->
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Opinion_type')" prop="adviseType">
                <el-select v-model="formInline.adviseType">
                  <el-option label="全部" value="" />
                  <el-option label="停车服务" :value="1" />
                  <el-option label="订单服务" :value="2" />
                  <el-option label="车辆管理" :value="3" />
                  <el-option label="功能异常" :value="4" />
                  <el-option label="商务合作" :value="5" />
                  <el-option label="产品建议" :value="6" />
                  <el-option label="其他" :value="7" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Last_information_time')">
                <el-date-picker
                  v-model="dateArray"
                  type="datetimerange"
                  range-separator="~"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                type="primary"
                icon="el-icon-search"
                :loading="loading"
                @click="
                  pageNum = 1;
                  searchData();
                "
                >查询
              </AuthorityComponent>
              <el-button @click="resetForm" icon="el-icon-delete" type="info">{{ $t('button.reset') }}</el-button>
            </div>
          </div>

          <!-- <el-button type="primary" icon="el-icon-tickets" v-if='$route.meta.authority.button.export' @click="exportFn">{{ $t('button.export') }}</el-button> -->
        </el-form>
      </div>
      <complaintsProposalsBox
        :infoSize="4"
        :dataDetail="tableDatailist"
        :detailFlag="dialogVisible"
        @close="dialogVisible = false"
      ></complaintsProposalsBox>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70" align='center' ></el-table-column>-->
          <el-table-column
            :prop="item.prop"
            align="center"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                v-if="item.prop == 'parkRecordId'"
                v-clipboard:copy="scope.row.parkRecordId"
                v-clipboard:success="onCopy"
                class="orderNumberStyle"
              >
                <p>{{ scope.row.parkRecordId }}</p>
              </span>
              <span v-else>
                <p>
                  {{ item.formatter ? item.formatter(scope.row) : scope.row[item.prop] }}
                </p>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('searchModule.Last_information_time')"
            prop="updatedTime"
            :sortable="true"
            align="center"
          />
          <AuthorityComponent
            ComponentName="el-table-column"
            :permission="['button.detail']"
            label="操作"
            align="center"
            width="100"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="todetail(scope.row)">详情</el-button>
            </template>
          </AuthorityComponent>
        </el-table>
      </div>
      <complaintsProposalsBoxTwo
        :infoSize="4"
        :replydetail="replyList"
        :detailFlag="dialogVisibleTwo"
        @close="shutdown"
      ></complaintsProposalsBoxTwo>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat, exportExcelNew, setIndex } from "../../common/js/public.js";
import complaintsProposalsBox from "../../components/complaintsProposalsBox/complaintsProposalsBox";
import complaintsProposalsBoxTwo from "../../components/complaintsProposalsBox/complaintsProposalsBoxTwo";
export default {
  name: "complaintsProposals",
  data() {
    const startDate = this.$moment().subtract(7, "days").format("YYYY-MM-DD 00:00:00");
    const endDate = this.$moment().format("YYYY-MM-DD 23:59:59");
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value) && value) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      dialogVisibleTwo: false,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      ringChart: {},
      pieChart: {},
      searchRule: {
        mobile: [
          {
            validator: checkPhone,
            trigger: "blur",
          },
        ],
      },
      tableCols: [
        {
          prop: "memberAdviseId",
          label: "意见ID",
        },
        {
          prop: "mobile",
          label: this.$t("list.phone_number"),
        },
        {
          prop: "content",
          label: "标题",
        },
        {
          prop: "operateType",
          label: "意见状态",
          formatter: (row) => {
            if (!row.operateType) {
              return;
            }
            const result = ["", "待回复", "", "已回复"];
            return result[row.operateType];
          },
        },
        {
          prop: "adviseType",
          label: "意见类型",
          formatter: (row) => {
            if (!row.adviseType) {
              return;
            }
            const result = [
              "",
              "停车服务",
              "订单服务",
              "车辆管理",
              "功能异常",
              "商务合作",
              "产品建议",
              "其他",
            ];
            return result[row.adviseType];
          },
        },
        {
          prop: "parkRecordId",
          label: this.$t("list.Parking_Order_ID"),
        },
      ],
      tableDatailist: [],
      replyList: [],
      tableData: [],
      formInline: { operateType: "", adviseType: "" },
      dateArray: [startDate, endDate],
      memberAdviseIds: "",
      authority: this.$route.meta.authority.button,
      contentList: [],
      timeout: null,
    };
  },
  methods: {
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
    shutdown() {
      this.dialogVisibleTwo = false;
      this.searchData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.dialogVisible = true;
        this.feedback(data);
      } else if (cmd == "b") {
        this.feedback(data);
        this.dialogVisibleTwo = true;
      }
    },
    // 验证时间
    showLog() {
      if (this.dateArray && this.dateArray[0] > this.dateArray[1]) {
        this.$alert("结束时间不能小于开始时间", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      if (this.dateArray) {
        const startTime = this.$moment(this.dateArray[0]).unix();
        const endTime = this.$moment(this.dateArray[1]).unix();
        if ((endTime - startTime) / 3600 / 24 > 31) {
          this.$alert("查询时间不能大于31天", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return false;
        }
      }
      return true;
    },
    /* 反馈建议查询 */
    feedback(data) {
      this.tableDatailist = [];
      this.replyList = [];
      this.$axios
        .get("/acb/2.0/member/advise/detail", {
          data: {
            memberAdviseId: data.memberAdviseId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableDatailist = res.value;
            this.replyList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    resetForm() {
      this.formInline = { operateType: "", adviseType: "" };
      this.memberAdviseIds = "";
      const startDate = this.$moment().subtract(7, "days").format("YYYY-MM-DD 00:00:00");
      const endDate = this.$moment().format("YYYY-MM-DD 23:59:59");
      this.dateArray = [startDate, endDate];
    },
    // 搜索
    searchData() {
      if (!this.showLog()) {
        return;
      }
      if (this.formInline.adviseId && !/^\d+$/.test(this.formInline.adviseId)) {
        this.$alert("意见ID必须为数字", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (!this.memberAdviseIds) {
        delete this.formInline.memberAdviseIds;
      }
      this.loading = true;
      const opt = {
        method: "get",
        url: "/acb/2.0/member/advise/list",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          adviseId: this.formInline.adviseId,
          mobile: this.formInline.mobile,
          content: this.formInline.content,
          operateType: this.formInline.operateType,
          adviseType: this.formInline.adviseType,
          memberAdviseIds: this.formInline.memberAdviseIds,
        },
        success: (res) => {
          if (res.state == 0) {
            this.loading = false;
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.loading = false;
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        },
        fail: (err) => {
          this.loading = false;
        },
      };
      if (this.dateArray) {
        opt.data.startTime = this.$moment(this.dateArray[0]).format("YYYY-MM-DD HH:mm:ss");
        opt.data.endTime = this.$moment(this.dateArray[1]).format("YYYY-MM-DD HH:mm:ss");
      }
      this.$request(opt);
    },
    // 导出
    exportFn() {
      if (!this.showLog()) {
        return;
      }
      exportExcelNew("/acb/2.0/member/advise/list/export", this.formInline, "post");
    },
    // 进入反馈详情页
    todetail(row) {
      this.$router.push({ path: "/feedbackDetail", query: { memberAdviseId: row.memberAdviseId } });
    },
    // 获取所有意见内容
    getContentList() {
      const opt = {
        url: "/acb/2.0/member/advise/contentList",
        method: "get",
        data: {},
        success: (res) => {
          this.contentList = res.value;
        },
      };
      this.$request(opt);
    },
    querySearchAsync(queryString, cb) {
      const contentList = this.contentList;
      const results = queryString
        ? contentList.filter(this.createStateFilter(queryString))
        : contentList;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        for (let i in results) {
          results[i].value = results[i].content;
        }
        cb(results);
      }, 500 * Math.random());
    },
    createStateFilter(queryString) {
      return (state) => {
        return state.content.indexOf(queryString) >= 0;
      };
    },
    handleSelect(item) {
      this.formInline.memberAdviseIds = item.memberAdviseId;
    },
  },
  components: {
    complaintsProposalsBox,
    complaintsProposalsBoxTwo,
  },
  mounted() {
    // this.searchData()
    this.getContentList();
  },
  activated() {
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.orderNumberStyle
  display: inline-block;
  color: #0f6eff;
  margin-right: 5px;
</style>
