<template>
  <div>
    <transition name="fade">
      <div class="mask" v-show="detailFlag">
        <transition name="fade">
          <div class="detail" v-show="detailFlag">
            <div class="header">
              {{ infoDetail.parkName ? infoDetail.parkName : "查看详情" }}
              <i class="el-icon-close" @click="$emit('close')"></i>
            </div>
            <div class="content">
              <div class="picWrapper">
                <el-tabs v-model.trim="activeName">
                  <el-tab-pane label="反馈建议回复" name="first">
                    <div class="parkDeatail">
                      <div class="parkInfo">
                        <h2 class="parkingInfo">
                          <a class="parkingInfo-on"></a>
                          用户反馈内容
                        </h2>
                        <div class="parkinfoDeatail">
                          <div><span></span>{{ dataDetail.content }}</div>
                          <div style="height: 2rem">
                            <span> 用户：{{ dataDetail.memberName }} </span>
                            <span style="float: right"> 时间：{{ dataDetail.adviseTime }} </span>
                          </div>
                        </div>
                      </div>
                      <div class="parkInfo">
                        <div class="parkingInfo">
                          <a class="parkingInfo-on"></a>
                          工作人员答复
                        </div>
                        <div class="parkinfoDeatail">
                          <div><span></span>{{ dataDetail.replyContent }}</div>
                          <div style="height: 2rem">
                            <span> 回复人：{{ dataDetail.sysUserName }} </span>
                            <span style="float: right"> 时间：{{ dataDetail.replyTime }} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 入场 -->
                    <div v-if="entryPic.length" style="padding-left: 15px">
                      <div class="parkingInfo"><a class="parkingInfo-on"></a>入场照片</div>
                      <div
                        class="picBox"
                        v-for="value in entryPic"
                        :key="value.accessKey"
                        v-show="value.imageType != 22"
                      >
                        <div class="title">
                          {{
                            value.imageType == 1
                              ? "全景照片"
                              : value.imageType == 2
                              ? "车辆照片"
                              : value.imageType == 3
                              ? "车牌照片"
                              : ""
                          }}
                        </div>
                        <div class="pic">
                          <div
                            @click="showBigPic(value.picUrl)"
                            v-if="value.imageType == 1"
                            :style="{
                              width: '100%',
                              height: '100%',
                              background:
                                'white url(' + value.picUrl + ') center/contain no-repeat',
                            }"
                          ></div>
                          <img
                            v-if="value.imageType != 1"
                            :src="value.picUrl"
                            width="100%"
                            height="100%"
                            @click="showBigPic(value.picUrl)"
                          />
                        </div>
                      </div>
                      <div style="clear: both"></div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
                <el-row>
                  <el-button
                    @click="$emit('close')"
                    style="position: relative; left: 46%; margin-top: 1rem"
                    >关闭</el-button
                  >
                </el-row>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
    <transition name="fade">
      <div
        class="mask"
        v-show="bigPic"
        :style="{ 'z-index': '10000', 'background-image': picUrl }"
        @click="bigPic = false"
      ></div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "complaintsProposalsBox",
  data() {
    return {
      showParkRecord: false,
      activeName: "first",
      bigPic: false,
      picUrl: "",
      defaultImg: "car-pic.png",
      page: 1,
      pageSize: 15,
    };
  },
  watch: {
    parkRecordIdData: {
      handler(curVal, oldVal) {
        if (curVal.length > 0) {
          this.showParkRecord = this.parkRecordIdData[0].refundReasonName == "出场延时";
        }
      },
      deep: true,
    },
    rowData: {
      handler(curVal, oldVal) {
        this.activeName = "first";
      },
      immediate: true,
    },
  },
  mounted() {},
  computed: {},
  methods: {
    // 格式化日期
    dateFormat(date, fmt) {
      fmt = fmt || "yyyy-MM-dd HH:mm:ss";
      var o = {
        // 月份
        "M+": date.getMonth() + 1,
        // 日
        "d+": date.getDate(),
        // 小时
        "H+": date.getHours(),
        // 分
        "m+": date.getMinutes(),
        // 秒
        "s+": date.getSeconds(),
        // 季度
        "q+": Math.floor((date.getMonth() + 3) / 3),
        // 毫秒
        S: date.getMilliseconds(),
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
      var h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
      var m = date.getMinutes() < 10 ? "0" + date.getMinutes() + ":" : date.getMinutes() + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    closeFun() {
      this.$emit("close");
      this.activeName = "first";
    },
    handleCurrentChange(val) {
      this.$emit("getOrder", val);
    },
    showBigPic(url) {
      if (!url) {
        return false;
      }
      this.picUrl = `url("${url}")`;
      this.bigPic = true;
    },
  },
  props: {
    rowData: {
      default: () => {
        return {};
      },
    },
    dataDetail: {
      default: function () {
        return {};
      },
    },
    infoDetail: {
      default: function () {
        return {};
      },
    },
    infoSize: {
      type: Number,
      default: 6,
    },
    total: {
      type: Number,
      default: 0,
    },
    tableOrderdata: {
      default: function () {
        return [];
      },
    },
    parkRecordIdData: {
      default: function () {
        return [];
      },
    },
    tableOrder: {
      default: function () {
        return {};
      },
    },
    parkRecordIdTable: {
      default: function () {
        return {};
      },
    },
    exitPic: {
      default: function () {
        return [];
      },
    },
    entryPic: {
      default: function () {
        return [];
      },
    },
    detailFlag: {
      default: false,
    },
  },
  components: {},
  created() {},
};
</script>
<style media="screen">
.picWrapper .el-tabs__content {
  overflow-y: auto;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.pagerWrapper
  .el-pagination
    text-align: right;
    padding-top: 20px;
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0
}
.detail
  width: 61.4%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128,145,165,0.30);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;
  .header
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;
    .el-icon-close
      float: right;
      margin-top: 14px;
  .content
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
    .picWrapper
      .picBox
        float: left;
        width: 33.33333%;
        padding: 0 20px 15px 0;
        box-sizing: border-box;
      .title
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #8091A5;
        letter-spacing: 0;
        line-height: 16px;
      .pic
        background: url("car-pic.png") center no-repeat;
        width: 100%;
        height: 150px;
        border: 1px solid #8091A5;
        margin-top: 10px;
        overflow: hidden;
    .infoWrapper
      border-top: 1px solid #D3DCE6;
      padding-top: 20px;
      .title
        height: 42px;
        line-height: 42px;
        color: #8391a5;
        font-size: 14px;
        width: 100%;
        border-bottom: 1px solid #d1dbe5;
        margin: 0 0 15px;
      .info
        font-size: 12px;
        color: #313541;
        letter-spacing: 0;
        line-height: 32px;
        overflow: hidden;
        .infoItem
          width: 33.3333%;
          box-sizing: border-box;
          float: left;
          .label
            width: 100px;
            text-align: right;
            display: inline-block;
    .footer
      clear: both;
      text-align: center;
      margin-top: 50px;
.mask
  background: rgba(49,53,65,0.60) no-repeat center/contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
</style>

<style scoped>
.parkTitle {
  /* border-bottom:1px solid #e1e1e1; */
}
.parkDeatail {
  padding-left: 15px;
}
.parkInfo {
  border-bottom: 2px solid #e0e6ed;
  padding-bottom: 12px;
}
.parkingInfo {
  font-family: "PingFang";
  position: relative;
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  padding-left: 14px;
  font-weight: 500;
}
.parkingInfo-on {
  position: absolute;
  left: 0;
  top: 31%;
  width: 4px;
  height: 16px;
  background: #0099fa;
}
.parkinfoDeatail {
  /* display: flex; */
  color: #566475;
}
.parkinfoDeatail div:first-child {
  width: 100%;
  height: 7rem;
  line-height: 30px;
  overflow-y: scroll;
}
.parkinfoDeatail div:first-child::-webkit-scrollbar {
  display: none;
}
.parkinfoDeatail div:first-child > span:first-child {
  width: 100px;
  /* display: inline-block; */
}
.parkinfoDeatail div:last-child {
  width: 100%;
  height: 7rem;
  line-height: 30px;
}
.parkinfoDeatail div:last-child > span:first-child {
  width: 100px;
  /* display: inline-block; */
}
.parkTitle span {
  text-align: center;
  display: inline-block;
  width: 100px;
  line-height: 30px;
  border: 1px solid #e1e1e1;
  border-bottom: 0;
}
.picTitle {
  line-height: 30px;
}
.detail1 {
  line-height: 25px;
}
.pic-title {
  padding-left: 15px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 900;
}
</style>
