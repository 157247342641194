<template>
  <div>
    <transition name="fade">
      <div class="mask" v-show="detailFlag">
        <transition name="fade">
          <div class="detail" v-show="detailFlag">
            <div class="header">
              {{ infoDetail.parkName ? infoDetail.parkName : "查看详情" }}
              <i class="el-icon-close" @click="shut"></i>
            </div>
            <div class="content">
              <div class="picWrapper">
                <el-tabs v-model.trim="activeName">
                  <el-tab-pane label="反馈建议查看" name="first">
                    <div class="parkDeatail">
                      <div class="parkInfo">
                        <h2 class="parkingInfo">
                          <a class="parkingInfo-on"></a>
                          用户反馈内容
                        </h2>
                        <div class="parkinfoDeatail">
                          <div><span></span>{{ replydetail.content }}</div>
                          <div style="height: 2rem">
                            <span>
                              <!-- 用户：{{0}} -->
                            </span>
                            <span style="float: right">
                              <!-- 时间：{{0}} -->
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="parkInfo" style="border: 0px">
                        <div class="parkingInfo">
                          <a class="parkingInfo-on"></a>
                          <span style="float: left">答复常用语</span>
                          <el-form
                            :inline="true"
                            label-position="right"
                            :model="formInline"
                            class="demo-form-inline"
                            style="float: left; margin-left: 10px"
                          >
                            <el-form-item>
                              <!-- <el-select v-model.trim="formInline.state">
                            <el-option :label="v.label" :value="v.value" :key="v.label" v-for="v in stateList"></el-option>
                          </el-select> -->
                              <el-select
                                v-model.trim="formInline.state"
                                @change="getNumber"
                                style="width: 300px"
                                placeholder=""
                              >
                                <el-option label="请选择" value=""></el-option>
                                <el-option label="邯郸爱泊车收费标准" value="0"></el-option>
                                <el-option label="有欠费记录怎么缴费？" value="1"></el-option>
                                <el-option
                                  label="会员车辆收到短信提示已驶离，但车辆仍在车场"
                                  value="2"
                                ></el-option>
                                <el-option
                                  label="车辆未进入车场只停在车场附近、车辆路过车场，被抓拍计时收费"
                                  value="3"
                                ></el-option>
                                <el-option
                                  label="车辆在视频车场发生剐蹭、丢失物品的，要求查看视频监控"
                                  value="4"
                                ></el-option>
                                <el-option label="车辆解绑怎么操作" value="5"></el-option>
                              </el-select>
                            </el-form-item>
                          </el-form>
                        </div>
                        <div class="parkinfoDeatail">
                          <div :model="formInline">
                            <el-input
                              style="position: relative; top: -25px"
                              type="textarea"
                              :rows="4"
                              :autosize="{ minRows: 4, maxRows: 4 }"
                              size="720"
                              placeholder="我们的客户将会联系您。"
                              v-model="formInline.content"
                              maxlength="500"
                            >
                            </el-input>
                          </div>
                          <p style="text-align: right; margin-top: -20px">500字以内</p>
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
                <el-row>
                  <el-button
                    type="primary"
                    @click="replyDetail"
                    style="position: relative; left: 41%; margin-top: 1rem"
                    >发送</el-button
                  >
                  <el-button
                    @click="$emit('close')"
                    style="position: relative; left: 44%; margin-top: 1rem"
                    >关闭</el-button
                  >
                </el-row>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
    <transition name="fade">
      <div
        class="mask"
        v-show="bigPic"
        :style="{ 'z-index': '10000', 'background-image': picUrl }"
        @click="bigPic = false"
      ></div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "complaintsProposalsBoxTwo",
  data() {
    return {
      memberAdviseId: this.replydetail.memberAdviseId,
      showParkRecord: false,
      activeName: "first",
      bigPic: false,
      picUrl: "",
      defaultImg: "car-pic.png",
      page: 1,
      pageSize: 15,
      formInline: {
        state: "",
        content: "",
      },
    };
  },
  watch: {
    parkRecordIdData: {
      handler(curVal, oldVal) {
        if (curVal.length > 0) {
          this.showParkRecord = this.parkRecordIdData[0].refundReasonName == "出场延时";
        }
      },
      deep: true,
    },
    rowData: {
      handler(curVal, oldVal) {
        this.activeName = "first";
      },
      immediate: true,
    },
  },
  mounted() {},
  computed: {},
  methods: {
    shut() {
      this.formInline.state = "";
      this.formInline.content = "";
      this.$emit("close");
    },
    /* 回复意见反馈 */
    replyDetail(data) {
      if (this.formInline.content == "") {
        this.$alert("回复不能为空！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      } else {
        this.$axios
          .post("/acb/2.0/member/advise/reply", {
            data: {
              memberAdviseId: this.replydetail.memberAdviseId,
              content: this.formInline.content,
            },
          })
          .then((res) => {
            if (res.state == 0) {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
              this.shut();
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      }
    },
    getNumber() {
      if (this.formInline.state == "0") {
        this.formInline.content =
          "1、临停时间：20分钟免费。日间时段：07:30:00-19:30:00夜间时段：19:30:00-07:30:002、长停小型车收费一类区域：昼间首2小时4元，超过2小时按1元/30分钟，15元封顶；夜间免费。3、长停小型车收费二类区域：昼间首2小时2.5元，超过2小时按0.5元/30分钟，7元封顶；夜间免费。注释：1、特殊场景计费说明：停车不满首2小时按2小时计算，超过首时长停车不满30分钟按30分钟计算。2、停车仅纯夜间时段进出场，按夜间计费。3、以上计费规则仅供参考，具体收费规则以车场为准。";
      } else if (this.formInline.state == "1") {
        this.formInline.content =
          "您可以下载邯郸爱泊车手机APP自行缴费或者到任意非现金车场咨询收费管理员缴费。";
      } else if (this.formInline.state == "2") {
        this.formInline.content =
          "可能由于您的车附近的车辆离场，收费员操作失误，将您的车手动离场，或者其他车辆路过，将您的车挡住，由于摄像头抓拍失误，导致车辆离场。若产生二次计费，可拨打客服电话4001333990，经技术部核实后我公司可进行修改。";
      } else if (this.formInline.state == "3") {
        this.formInline.content =
          "请提供下您的车牌号，您在该车场停过车吗？若对计时抓拍有疑问可拨打智慧泊车客服电话4001333990，.如果是摄像头识别抓拍错误造成的，可进行后台处理，消除欠费。";
      } else if (this.formInline.state == "4") {
        this.formInline.content =
          "我公司监控设备用来抓拍车辆进、出车场的车牌号，进行计费，可能不能很全面的监控到车场内部情况，建议您可以报警，我公司协助调查（如车主执意要看视频需警方提供相应的协助调查的证明文件等）。";
      } else if (this.formInline.state == "5") {
        this.formInline.content =
          "请登录邯郸爱泊车app打开【会员中心】-【车辆管理】-找到需要解绑的车牌号，按住住车牌号向左滑动，右侧会出现解绑字样，点击【解绑】即可完成操作。";
      } else {
        this.formInline.content = "";
      }
    },
    // 格式化日期
    dateFormat(date, fmt) {
      fmt = fmt || "yyyy-MM-dd HH:mm:ss";
      var o = {
        // 月份
        "M+": date.getMonth() + 1,
        // 日
        "d+": date.getDate(),
        // 小时
        "H+": date.getHours(),
        // 分
        "m+": date.getMinutes(),
        // 秒
        "s+": date.getSeconds(),
        // 季度
        "q+": Math.floor((date.getMonth() + 3) / 3),
        // 毫秒
        S: date.getMilliseconds(),
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
      var h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
      var m = date.getMinutes() < 10 ? "0" + date.getMinutes() + ":" : date.getMinutes() + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    closeFun() {
      this.$emit("close");
      this.activeName = "first";
    },
    handleCurrentChange(val) {
      this.$emit("getOrder", val);
    },
    showBigPic(url) {
      if (!url) {
        return false;
      }
      this.picUrl = `url("${url}")`;
      this.bigPic = true;
    },
  },
  props: {
    rowData: {
      default: () => {
        return {};
      },
    },
    infoDetail: {
      default: function () {
        return {};
      },
    },
    replydetail: {
      default: function () {
        return [];
      },
    },
    infoSize: {
      type: Number,
      default: 6,
    },
    total: {
      type: Number,
      default: 0,
    },
    tableOrderdata: {
      default: function () {
        return [];
      },
    },
    parkRecordIdData: {
      default: function () {
        return [];
      },
    },
    tableOrder: {
      default: function () {
        return {};
      },
    },
    parkRecordIdTable: {
      default: function () {
        return {};
      },
    },
    exitPic: {
      default: function () {
        return [];
      },
    },
    entryPic: {
      default: function () {
        return [];
      },
    },
    detailFlag: {
      default: false,
    },
  },
  components: {},
  created() {
    this.formInline = {
      state: "",
      content: "",
    };
  },
};
</script>
<style media="screen">
.picWrapper .el-tabs__content {
  overflow-y: auto;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.pagerWrapper
  .el-pagination
    text-align: right;
    padding-top: 20px;
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0
}
.detail
  width: 61.4%;
  min-width: 700px;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128,145,165,0.30);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;
  .header
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;
    .el-icon-close
      float: right;
      margin-top: 14px;
  .content
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
    .picWrapper
      .picBox
        float: left;
        width: 33.33333%;
        padding: 0 20px 15px 0;
        box-sizing: border-box;
      .title
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #8091A5;
        letter-spacing: 0;
        line-height: 16px;
      .pic
        background: url("car-pic.png") center no-repeat;
        width: 100%;
        height: 150px;
        border: 1px solid #8091A5;
        margin-top: 10px;
        overflow: hidden;
    .infoWrapper
      border-top: 1px solid #D3DCE6;
      padding-top: 20px;
      .title
        height: 42px;
        line-height: 42px;
        color: #8391a5;
        font-size: 14px;
        width: 100%;
        border-bottom: 1px solid #d1dbe5;
        margin: 0 0 15px;
      .info
        font-size: 12px;
        color: #313541;
        letter-spacing: 0;
        line-height: 32px;
        overflow: hidden;
        .infoItem
          width: 33.3333%;
          box-sizing: border-box;
          float: left;
          .label
            width: 100px;
            text-align: right;
            display: inline-block;
    .footer
      clear: both;
      text-align: center;
      margin-top: 50px;
.mask
  background: rgba(49,53,65,0.60) no-repeat center/contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
</style>

<style scoped>
.parkTitle {
  border-bottom: 1px solid #e1e1e1;
}
.parkDeatail {
  padding-left: 15px;
}
.parkInfo {
  border-bottom: 1px solid #e0e6ed;
  padding-bottom: 12px;
}
.parkingInfo {
  font-family: "PingFang";
  position: relative;
  width: 60%;
  height: 42px;
  line-height: 35px;
  font-size: 16px;
  padding-left: 14px;
  font-weight: 500;
  margin-top: 8px;
}
.parkingInfo-on {
  position: absolute;
  left: 0;
  top: 31%;
  width: 4px;
  height: 16px;
  background: #0099fa;
}
.parkinfoDeatail {
  color: #566475;
}
.parkinfoDeatail div:first-child {
  width: 99%;
  margin-top: 1.5rem;
  border-radius: 3px;
  line-height: 41px;
}
.parkinfoDeatail div:first-child > span:first-child {
  width: 100px;
  /* display: inline-block; */
}
.parkinfoDeatail div:last-child {
  width: 99%;
  height: 7rem;
  border-radius: 3px;
  line-height: 41px;
}
.parkinfoDeatail div:last-child > span:first-child {
  width: 100px;
  display: inline-block;
}
.parkTitle span {
  text-align: center;
  display: inline-block;
  width: 100px;
  line-height: 30px;
  border: 1px solid #e1e1e1;
  border-bottom: 0;
}
.picTitle {
  line-height: 30px;
}
.detail1 {
  line-height: 25px;
}
.pic-title {
  padding-left: 15px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 900;
}
</style>
